import axios from 'axios'
import store from './../store/index'
import router from './../router/index'
import { colors } from '../utils/colors'

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  timeout: 60000
})

/* Autenthication */
const getAuthToken = () => `Bearer ${localStorage.getItem('legalToken')}`

const authInterceptor = (config) => {
  config.headers.Authorization = getAuthToken()
  return config
}

const responseInterceptor = (response) => {
  return response
}

/* Errors */
const errorInterceptor = (error) => {
  if (!error.response) {
    return Promise.reject(error)
  }

  switch (error.response.status) {
    case 400:
      console.log(error.response.status, error.message)
      break

    case 401:
      console.log('No autorizado', error.response)
      store.commit('SET_SNACKBAR', {
        text: ' session caducada',
        timeout: 3000,
        showing: true,
        color: colors.primary
      })
      localStorage.clear()
      router.push('/login')
      break
    case 403:
      store.commit('SET_SNACKBAR', {
        text: 'Acceso restringido al contenido solicitado',
        timeout: 5000,
        showing: true,
        color: colors.error
      })
      router.push('/portal/contracts')
      break
    case 404:
      store.commit('SET_SNACKBAR', {
        text: 'No se encontró el recurso solicitado',
        timeout: 5000,
        showing: true,
        color: colors.error
      })
      break
    default:
      store.commit('SET_SNACKBAR', {
        text: `Ocurrió un error ${error.message}`,
        timeout: 5000,
        showing: true,
        color: colors.error
      })

      console.error('Ocurrió un error', error.response.status, error.message)
      break
  }
  return Promise.reject(error)
}

/* Config */
httpClient.interceptors.request.use(authInterceptor)
httpClient.interceptors.response.use(responseInterceptor, errorInterceptor)

export default httpClient
