const profiles = [
  {
    name: 'Legal',
    permission: 1
  },
  {
    name: 'Manager',
    permission: 2
  },
  {
    name: 'Client',
    permission: 3
  },
  {
    name: 'Finance',
    permission: 3
  }
]

export {
  profiles
}
