export default [
  {
    path: '/',
    redirect: '/portal'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: 'login' */ '@/views/Login/Index')
  },
  {
    path: '/portal',
    name: 'Portal',
    component: () => import(/* webpackChunkName: 'portal' */ '@/views/Portal/Index'),
    redirect: '/portal/contracts',
    children: [
      {
        path: 'contracts',
        component: () => import(/* webpackChunkName: 'contracts' */ '@/components/Contract/Index')
      },
      {
        path: 'contracts/:id',
        component: () => import(/* webpackChunkName: 'contract-detail' */ '@/components/Contract/Detail'),
        props: true
      },
      {
        path: 'templates',
        component: () => import(/* webpackChunkName: 'templates' */ '@/components/ContractTemplate/Index')
      },
      {
        path: 'templates/:id',
        component: () => import(/* webpackChunkName: 'template-detail' */ '@/components/ContractTemplate/Detail'),
        props: true
      },
      {
        path: 'configurations',
        component: () => import(/* webpackChunkName: 'configurations' */ '@/components/Administration/Index'),
        redirect: '/portal/configurations/users',
        children: [
          {
            path: 'countries',
            component: () => import(/* webpackChunkName: 'countries' */ '@/components/Administration/Countries')
          },
          {
            path: 'users',
            component: () => import(/* webpackChunkName: 'users' */ '@/components/Administration/Users')
          },
          {
            path: 'areas',
            component: () => import(/* webpackChunkName: 'areas' */ '@/components/Administration/Areas')
          },
          {
            path: 'notifications',
            component: () => import(/* webpackChunkName: 'notifications' */ '@/components/Administration/Notifications')
          },
          {
            path: 'justifications',
            component: () => import(/* webpackChunkName: 'justifications' */ '@/components/Administration/Justifications')
          },
          {
            path: 'templatetypes',
            component: () => import(/* webpackChunkName: 'templatetypes' */ '@/components/Administration/TemplateTypes')
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect: '/portal'
  }
]
