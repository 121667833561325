import httpClient from './httpClient'

function getTemplates (params) {
  const url = '/get_templates/'
  return httpClient.get(url, { params })
}

function getTemplateDetail (params) {
  const url = '/get_template_detail/'
  return httpClient.get(url, { params })
}

function getTemplate (params) {
  const url = '/get_template/'
  return httpClient.get(url, { params })
}

function convertToWord (params) {
  const url = '/convert_to_word/'

  return httpClient.get(url, { params, responseType: 'blob' })
}

function saveTemplate (payload) {
  const url = '/save_template/'

  return httpClient.post(url, payload)
}

function editTemplate (payload) {
  const url = '/update_template/'

  return httpClient.post(url, payload)
}

function publishedToggle (payload) {
  const url = `/update_template_status/${payload.id}/`

  return httpClient.patch(url, payload.data)
}

function getQuestionsTemplate (params) {
  const url = '/get_questions_templates/'

  return httpClient.get(url, { params })
}

function getContentByFile (payload) {
  const formData = new FormData()
  const files = payload.files

  for (let index = 0; index < files.length; index++) {
    const file = files[index]
    formData.append('file', file)
  }

  const url = '/convert/'
  return httpClient.post(url,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

function getTemplateHistory (params) {
  const url = '/history/'
  return httpClient.get(url, { params })
}

function getFilterTemplates (params) {
  const url = '/filter_templates/'
  return httpClient.get(url, { params })
}

function removeTemplate (templateId) {
  const url = `/remove_template/${templateId}`
  return httpClient.get(url)
}

export {
  getContentByFile,
  saveTemplate,
  getTemplates,
  getTemplateDetail,
  getTemplate,
  getQuestionsTemplate,
  editTemplate,
  convertToWord,
  publishedToggle,
  getTemplateHistory,
  getFilterTemplates,
  removeTemplate
}
