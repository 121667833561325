export default {
  profile: localStorage.getItem('profile'),
  username: '',
  area: localStorage.getItem('area'),
  country: localStorage.getItem('country'),
  token: localStorage.getItem('legalToken'),
  snackbar: {
    showing: false,
    text: '',
    timeout: 1000,
    color: 'success'
  },
  countries: [],
  templateTypes: [],
  folderSelection: {},
  allFolders: [],
  contractLeftFolders: [],
  templateLeftFolders: [],
  navNotifications: [],
  navNotificationsNumber: 0
}
