<template>
  <div id="app">
    <!-- GLOBAL SNACKBAR -->
    <v-snackbar
      v-model="snackbar.showing"
      :color="snackbar.color"
      light
      text
      outlined
      :timeout="snackbar.timeout"
      top
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          icon
          :color="snackbar.color"
          class="ma-2"
          v-bind="attrs"
          @click="closeSnackbar"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <router-view/>
  </div>
</template>
<script>

import { mapState, mapMutations } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapState([
      'snackbar'
    ])
  },

  methods: {
    ...mapMutations({
      setSnackbar: 'SET_SNACKBAR'
    }),

    closeSnackbar () {
      this.setSnackbar({
        showing: false
      })
    }
  }
}
</script>

<style lang="scss">
body {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
}

#app {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Lato';
  src: url(~@/assets/fonts/Lato/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url(~@/assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}
</style>
