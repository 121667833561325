import httpClient from './httpClient'

const END_POINT = '/user/login/'

function getAuthentication (payload) {
  return httpClient.post(END_POINT, payload)
}

export {
  getAuthentication
}
