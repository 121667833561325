import httpClient from './httpClient'

function getCountries () {
  const url = '/countries/'
  return httpClient.get(url)
}

function getTemplateTypes () {
  const url = '/templates_types/'
  return httpClient.get(url)
}

function getAllFolders () {
  const url = '/folders/'
  return httpClient.get(url)
}

function getContractLeftFolders (params) {
  const url = '/list_folders/'
  return httpClient.get(url, { params })
}

function getTemplateLeftFolders (params) {
  const url = '/templates_folders/'
  return httpClient.get(url, { params })
}

function getNavNotifications (params) {
  const url = '/get_notifications/'
  return httpClient.get(url, { params })
}

export {
  getCountries,
  getTemplateTypes,
  getAllFolders,
  getContractLeftFolders,
  getNavNotifications,
  getTemplateLeftFolders
}
