import httpClient from './httpClient'

function saveDocument (payload) {
  const url = '/save_document/'
  return httpClient.post(url,
    payload
  )
}

function getDocuments (params) {
  const url = '/get_documents/'
  return httpClient.get(url, { params })
}

function removeFolder (folderId) {
  const url = `/remove_folder/${folderId}`
  return httpClient.get(url)
}

function getDocument (id) {
  const url = `/get_document/${id}/`
  return httpClient.get(url)
}

function getDocumentDetail (id) {
  const url = `get_document_detail/${id}/`
  return httpClient.get(url)
}

function newFolder (payload) {
  const url = '/folders/'
  return httpClient.post(url, payload)
}

function uploadContractFile (payload) {
  const url = '/upload_contract_file/'
  const formData = new FormData()
  formData.append('file', payload.file)
  formData.append('document', payload.document)
  formData.append('attach', payload.attach)
  formData.append('observation', payload.observation)
  formData.append('addendum', payload.addendum)

  return httpClient.post(url, formData)
}

function getAllStatus (payload) {
  const url = '/status_documents/'
  return httpClient.get(url)
}

function getRejectionStatus (payload) {
  const url = '/justifications/'
  return httpClient.get(url)
}

function updateStatusReview (payload) {
  const url = '/update_status_review/'
  return httpClient.put(url, payload)
}

function getAttachmentTemplates (payload) {
  const url = `get_attachment_templates/?document=${payload.document}`
  return httpClient.get(url)
}

function getAddendumTemplates (payload) {
  const url = `get_addendum_templates/?document=${payload.document}`
  return httpClient.get(url)
}

function setValidity (payload) {
  const url = `/document_validity/${payload.document}/`
  const formData = new FormData()
  formData.append('document', payload.document)
  formData.append('start', payload.start)
  formData.append('end', payload.end)
  formData.append('reminder', payload.reminder)
  if (payload.file !== undefined) {
    formData.append('file', payload.file)
  }

  return httpClient.put(url, formData)
}

function getObservations (payload) {
  const url = `/observations/?document=${payload.document}`
  return httpClient.get(url)
}

function saveObservation (payload) {
  const url = '/observations/'
  return httpClient.post(url, payload)
}

function getDocumentsAttached (payload) {
  let url = ''
  if (payload.addendum !== undefined) {
    url = `/get_document_files/?addendum=1&document=${payload.document}`
  } else {
    url = `/get_document_files/?document=${payload.document}`
  }
  return httpClient.get(url)
}

function getManagers (payload) {
  const url = `/get_managers?country=${payload.country}`
  return httpClient.get(url)
}

function getDocumentStatusReview (payload) {
  const url = `/document_status_review/?document=${payload.document}`
  return httpClient.get(url)
}

function setDocumentStatusReview (payload) {
  const url = `/document_status_review/?document=${payload.document}&status=${payload.status}`
  return httpClient.put(url)
}

function shareDocument (payload) {
  const url = '/shared_users/'
  return httpClient.post(url, payload)
}

function getSharedUsers (payload) {
  const url = `/get_shared_users/${payload.document}/`
  return httpClient.get(url)
}

function getFinanceUsers (payload) {
  const url = '/get_finance_users/'
  return httpClient.get(url)
}

function listFolders (payload) {
  const url = '/list_folders/'
  return httpClient.get(url)
}

function getFilterDocuments (params) {
  const url = '/filter_documents/'
  return httpClient.get(url, { params })
}

function moveContract (payload) {
  const url = '/move_contract/'
  return httpClient.post(url, payload)
}

function removeDocument (documentId) {
  const url = `/remove_document/${documentId}`
  return httpClient.get(url)
}

function getContractNotifications (params) {
  const url = '/get_notifications/'
  return httpClient.get(url, { params })
}

export {
  newFolder,
  getDocument,
  saveDocument,
  getDocuments,
  getAllStatus,
  getDocumentDetail,
  uploadContractFile,
  getRejectionStatus,
  updateStatusReview,
  getAttachmentTemplates,
  setValidity,
  getObservations,
  saveObservation,
  getAddendumTemplates,
  getDocumentsAttached,
  getManagers,
  getDocumentStatusReview,
  setDocumentStatusReview,
  shareDocument,
  getSharedUsers,
  getFinanceUsers,
  listFolders,
  getFilterDocuments,
  moveContract,
  getContractNotifications,
  removeDocument,
  removeFolder
}
