/* Contract Store */

import { isLegalProfile } from '@/helpers/permissionValidate'

import {
  newFolder,
  saveDocument,
  getDocument,
  getDocuments,
  getAllStatus,
  getDocumentDetail,
  uploadContractFile,
  getRejectionStatus,
  updateStatusReview,
  getAttachmentTemplates,
  getAddendumTemplates,
  setValidity,
  getObservations,
  saveObservation,
  getDocumentsAttached,
  getManagers,
  getDocumentStatusReview,
  setDocumentStatusReview,
  shareDocument,
  getSharedUsers,
  getFinanceUsers,
  listFolders,
  getFilterDocuments,
  moveContract,
  getContractNotifications,
  removeDocument,
  removeFolder
} from '@/api/contract.api'

export default {
  namespaced: true,
  state: {
    contracts: null,
    folders: null,
    topFolders: null,
    goBack: {
      country: '',
      level: 0,
      folder: '',
      goBackNav: null
    },
    currentContract: {
      idContract: null,
      form: {
        isValid: true,
        typeContract: '',
        contractName: '',
        template: '',
        folder: '',
        cmProposal: [],
        isFinances: false,
        shareToManagers: [],
        financeUsers: []
      },
      content: {
        fullContent: '',
        questions: [],
        templateCountry: ''
      }
    },
    drawer: false,
    showAddendumInfo: {
      show: false
    }
  },

  mutations: {
    SET_CONTRACTS (state, payload) {
      state.contracts = payload
    },

    SET_FOLDERS (state, payload) {
      state.folders = payload
    },

    SET_TOP_FOLDERS (state, payload) {
      state.topFolders = payload
    },

    SET_GO_BACK (state, payload) {
      state.goBack = payload
    },

    SET_LEVEL_TOP_FOLDERS (state, payload) {
      state.topFolders.level = payload
    },

    SET_CURRENT_CONTRACT_CONTENT (state, payload) {
      state.currentContract.content = payload
    },

    SET_CURRENT_CONTRACT (state, payload) {
      state.currentContract = payload
    },

    SET_CURRENT_CONTRACT_FULLCONTENT (state, payload) {
      state.currentContract.content.fullContent = payload
    },

    SET_CURRENT_CONTRACT_NAME (state, payload) {
      state.currentContract.form.contractName = payload
    },

    SET_DETAIL_CONTRACT (state, payload) {
      state.detailContract = payload
    },

    SET_DRAWER (state, payload) {
      state.drawer = payload
    },

    SET_SHOW_ADDENDUM_INFO (state, payload) {
      state.showAddendumInfo.show = payload
    }
  },

  actions: {
    saveContractDocument ({ dispatch }, payload) {
      return saveDocument(payload)
        .then(() => {
        })
    },

    getDocuments ({ commit }, payload) {
      const goBackAux = { ...payload } // Copia del payload para guardar en goBack state
      if (payload?.goBackNav) delete payload.goBackNav
      if (!isLegalProfile()) {
        if (!payload) payload = {}
        payload.country = localStorage.getItem('country')
        payload.user = localStorage.getItem('user')
      }
      if (payload !== undefined) {
        if (payload.level === undefined) {
          payload.level = 0
        }
      }
      return getDocuments(payload)
        .then(response => {
          commit('SET_CONTRACTS', response.data.contracts)
          commit('SET_FOLDERS', response.data.folders)
          commit('SET_TOP_FOLDERS', response.data)
          // Se agrega data para validar las carpetas seleccionadas previamente al seleccionar un contrato
          if (response.data.contracts.length > 0) {
            const uniqueArrayById = Array.from(new Set(goBackAux.goBackNav.map(JSON.stringify))).map(JSON.parse)
            goBackAux.goBackNav = uniqueArrayById
            commit('SET_GO_BACK', goBackAux)
          }
          return response
        })
        .catch(error => {
          console.log(error)
        })
    },

    getDocument ({ commit }, payload) {
      return getDocument(payload)
        .then(response => {
          return response
        })
    },

    getDocumentDetail ({ commit }, payload) {
      return getDocumentDetail(payload)
        .then(response => {
          return response
        })
    },

    newFolder ({ commit }, payload) {
      return newFolder(payload)
        .then(response => {
          return response
        })
    },

    uploadContractFile ({ commit }, payload) {
      return uploadContractFile(payload)
        .then(response => {
          return response
        })
    },

    getAllStatus ({ commit }, payload) {
      return getAllStatus(payload)
        .then(response => {
          return response
        })
    },

    getRejectionStatus ({ commit }, payload) {
      return getRejectionStatus(payload)
        .then(response => {
          return response
        })
    },

    updateStatusReview ({ commit }, payload) {
      return updateStatusReview(payload)
        .then(response => {
          return response
        })
    },

    getAttachmentTemplates ({ commit }, payload) {
      return getAttachmentTemplates(payload)
        .then(response => {
          return response
        })
    },
    getAddendumTemplates ({ commit }, payload) {
      return getAddendumTemplates(payload)
        .then(response => {
          return response
        })
    },

    setValidity ({ commit }, payload) {
      return setValidity(payload)
        .then(response => {
          return response
        })
    },

    getObservations ({ commit }, payload) {
      return getObservations(payload)
        .then(response => {
          return response
        })
    },

    saveObservation ({ commit }, payload) {
      return saveObservation(payload)
        .then(response => {
          return response
        })
    },

    getDocumentsAttached ({ commit }, payload) {
      return getDocumentsAttached(payload)
        .then(response => {
          return response
        })
    },

    getManagers ({ commit }, payload) {
      return getManagers(payload)
        .then(response => {
          return response
        })
    },

    getDocumentStatusReview ({ commit }, payload) {
      return getDocumentStatusReview(payload)
        .then(response => {
          return response
        })
    },

    setDocumentStatusReview ({ commit }, payload) {
      return setDocumentStatusReview(payload)
        .then(response => {
          return response
        })
    },

    shareDocument ({ commit }, payload) {
      return shareDocument(payload)
        .then(response => {
          return response
        })
    },

    getSharedUsers ({ commit }, payload) {
      return getSharedUsers(payload)
        .then(response => {
          return response
        })
    },

    getFinanceUsers ({ commit }, payload) {
      return getFinanceUsers(payload)
        .then(response => {
          return response
        })
    },

    listFolders ({ commit }, payload) {
      return listFolders(payload)
        .then(response => {
          return response
        })
    },

    getFilterDocuments ({ commit }, payload) {
      return getFilterDocuments(payload)
        .then(response => {
          return response
        })
    },

    moveContract ({ commit }, payload) {
      return moveContract(payload)
        .then(response => {
          return response
        })
    },

    removeDocument ({ commit }, documentId) {
      return removeDocument(documentId)
    },

    removeFolder ({ commit }, folderId) {
      return removeFolder(folderId)
    },

    getContractNotifications ({ commit }, payload) {
      return getContractNotifications(payload)
        .then(response => {
          return response
        })
    }
  }
}
