import {
  getAdminCountries,
  addAdminCountries,
  updateAdminCountries,
  getAdminUsers,
  getAdminUser,
  updateAdminUsers,
  addAdminUser,
  getAdminAreas,
  getAdminArea,
  updateAdminAreas,
  getAdminJustifications,
  addAdminJustification,
  updateAdminJustification,
  getAdminNotifications,
  updateAdminNotification,
  getAdminTemplateTypes,
  addAdminTemplateType,
  updateAdminTemplateType
} from '@/api/administration.api'

export default {
  namespaced: true,
  state: {
    // contracts: null,
  },

  mutations: {
    // SET_SHOW_ADDENDUM_INFO (state, payload) {
    //   state.showAddendumInfo.show = payload
    // }
  },

  actions: {

    // Endpoints for countries

    getAdminCountries ({ dispatch }, payload) {
      return getAdminCountries(payload)
        .then(response => {
          return response
        })
    },

    addAdminCountries ({ dispatch }, payload) {
      return addAdminCountries(payload)
        .then(response => {
          return response
        })
    },

    updateAdminCountries ({ dispatch }, payload) {
      return updateAdminCountries(payload)
        .then(response => {
          return response
        })
    },

    // Endpoints for users

    getAdminUsers ({ dispatch }, payload) {
      return getAdminUsers(payload)
        .then(response => {
          return response
        })
    },

    getAdminUser ({ dispatch }, payload) {
      return getAdminUser(payload)
        .then(response => {
          return response
        })
    },

    updateAdminUsers ({ dispatch }, payload) {
      return updateAdminUsers(payload)
        .then(response => {
          return response
        })
    },

    addAdminUser ({ dispatch }, payload) {
      return addAdminUser(payload)
        .then(response => {
          return response
        })
    },

    // Endpoints for areas

    getAdminAreas ({ dispatch }, payload) {
      return getAdminAreas(payload)
        .then(response => {
          return response
        })
    },

    getAdminArea ({ dispatch }, payload) {
      return getAdminArea(payload)
        .then(response => {
          return response
        })
    },

    updateAdminAreas ({ dispatch }, payload) {
      return updateAdminAreas(payload)
        .then(response => {
          return response
        })
    },

    // Endpoints for justifications

    getAdminJustifications ({ dispatch }, payload) {
      return getAdminJustifications(payload)
        .then(response => {
          return response
        })
    },

    addAdminJustification ({ dispatch }, payload) {
      return addAdminJustification(payload)
        .then(response => {
          return response
        })
    },

    updateAdminJustification ({ dispatch }, payload) {
      return updateAdminJustification(payload)
        .then(response => {
          return response
        })
    },

    // Endpoints for notifications

    getAdminNotifications ({ dispatch }, payload) {
      return getAdminNotifications(payload)
        .then(response => {
          return response
        })
    },

    updateAdminNotification ({ dispatch }, payload) {
      return updateAdminNotification(payload)
        .then(response => {
          return response
        })
    },

    // Endpoints for template types

    getAdminTemplateTypes ({ dispatch }, payload) {
      return getAdminTemplateTypes(payload)
        .then(response => {
          return response
        })
    },

    addAdminTemplateType ({ dispatch }, payload) {
      return addAdminTemplateType(payload)
        .then(response => {
          return response
        })
    },

    updateAdminTemplateType ({ dispatch }, payload) {
      return updateAdminTemplateType(payload)
        .then(response => {
          return response
        })
    }

  }
}
