const colors = {
  primary: '#435CC8',
  secondary: '#E75CE0',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#E65100',
  dark: '#1E1041',
  background: '#F3F7FD'
}

export {
  colors
}
