/* Contract Templates Store */

import { isLegalProfile } from '@/helpers/permissionValidate'

import {
  getContentByFile,
  saveTemplate, getTemplates,
  getTemplateDetail,
  getTemplate,
  getQuestionsTemplate,
  editTemplate,
  convertToWord,
  publishedToggle,
  getTemplateHistory,
  getFilterTemplates,
  removeTemplate
} from '@/api/contractTemplate.api'

export default {
  namespaced: true,
  state: {
    templates: [],
    folders: null,
    topFolders: null,
    goBack: {
      country: '',
      level: 0,
      folder: '',
      goBackNav: null
    },
    currentTemplate: {
      idTemplate: null,
      configurationForm: {
        templateName: '',
        typeTemplate: '',
        templateCountry: '',
        isValid: false
      },
      content: {
        questionnaire: {
          questions: [],
          isValid: true
        },
        editorContent: ''
      },
      deletedQuestions: [],
      deletedOptions: []
    }
  },
  mutations: {
    SET_TEMPLATES (state, payload) {
      state.templates = payload
    },

    SET_TOP_FOLDERS (state, payload) {
      state.topFolders = payload
    },

    SET_GO_BACK (state, payload) {
      state.goBack = payload
    },

    SET_LEVEL_TOP_FOLDERS (state, payload) {
      state.topFolders.level = payload
    },

    SET_FOLDERS (state, payload) {
      state.folders = payload
    },

    SET_CURRENT_TEMPLATE (state, payload) {
      state.currentTemplate = payload
    },

    SET_CURRENT_ID (state, payload) {
      state.currentTemplate.idTemplate = payload
    },

    SET_EDITOR_CONTENT (state, payload) {
      state.currentTemplate.content.editorContent = payload.content
    },

    SET_CONFIGURATION_FORM (state, payload) {
      state.currentTemplate.configurationForm = payload
    },

    SET_QUESTIONNAIRE (state, payload) {
      state.currentTemplate.content.questionnaire.questions = payload.questions
      state.currentTemplate.content.questionnaire.isValid = payload.isValid
    },

    ADD_QUESTION (state) {
      const index = state.currentTemplate.content.questionnaire.questions.length
      state.currentTemplate.content.questionnaire.questions.push({
        index,
        name: null,
        active: true,
        typeQuestion: 'text',
        answerOptions: [],
        newOption: '',
        isAdding: false,
        isValid: false
      })
    },

    REMOVE_QUESTION (state, payload) {
      const questionIndex = state.currentTemplate.content.questionnaire.questions.indexOf(payload)
      if (questionIndex !== -1) {
        const question = state.currentTemplate.content.questionnaire.questions[questionIndex]

        if (question.idquestion) {
          state.currentTemplate.deletedQuestions.push({ idquestion: question.idquestion })
        }

        state.currentTemplate.content.questionnaire.questions.splice(questionIndex, 1)
      }
    },

    DUPLICATE_QUESTION (state, payload) {
      const index = state.currentTemplate.content.questionnaire.questions.length
      const newAnswerOptions = [...payload.answerOptions]
      const newQuestion = {
        index,
        name: payload.name,
        active: payload.active,
        typeQuestion: payload.typeQuestion,
        answerOptions: newAnswerOptions,
        isDuplicated: true,
        newOption: payload.newOption,
        isAdding: payload.isAdding,
        isValid: payload.isValid
      }
      state.currentTemplate.content.questionnaire.questions.push(newQuestion)
    },

    CHANGE_ADDING_OPTION (state, payload) {
      const questionIndex = state.currentTemplate.content.questionnaire.questions.indexOf(payload.question)

      if (questionIndex !== -1) {
        state.currentTemplate.content.questionnaire.questions[questionIndex].isAdding = payload.value
      }
    },

    ADD_OPTION_LIST (state, payload) {
      const questionIndex = state.currentTemplate.content.questionnaire.questions.indexOf(payload)

      if (questionIndex !== -1) {
        const question = state.currentTemplate.content.questionnaire.questions[questionIndex]

        const newObj = {
          value: question.newOption
        }
        question.answerOptions.push(newObj)
        question.isAdding = false
        question.newOption = ''
      }
    },

    REMOVE_OPTION_LIST (state, payload) {
      const questionIndex = state.currentTemplate.content.questionnaire.questions.indexOf(payload.question)

      if (questionIndex !== -1) {
        const optionIndex = state.currentTemplate.content.questionnaire.questions[questionIndex].answerOptions.indexOf(payload.option)
        const option = state.currentTemplate.content.questionnaire.questions[questionIndex].answerOptions[optionIndex]

        if (option.idanswer) {
          state.currentTemplate.deletedOptions.push({ idanswer: option.idanswer })
        }
        state.currentTemplate.content.questionnaire.questions[questionIndex].answerOptions.splice(optionIndex, 1)
      }
    },

    ADD_DELETED_OPTION (state, payload) {
      const questionIndex = state.currentTemplate.content.questionnaire.questions.indexOf(payload.question)

      if (questionIndex !== -1) {
        const optionIndex = state.currentTemplate.content.questionnaire.questions[questionIndex].answerOptions.indexOf(payload.option)
        state.currentTemplate.content.questionnaire.questions[questionIndex].answerOptions.splice(optionIndex, 1)
      }
    },

    SET_VALID_QUESTIONNAIRE (state, payload) {
      state.currentTemplate.content.questionnaire.isValid = payload.isValid
    }
  },
  actions: {
    getTemplates ({ commit }, payload) {
      const goBackAux = { ...payload } // Copia del payload para guardar en goBack state
      if (payload?.goBackNav) delete payload.goBackNav
      if (!isLegalProfile()) {
        if (!payload) payload = {}
        payload.country = localStorage.getItem('country')
        payload.status = 1
      }
      return getTemplates(payload)
        .then(response => {
          commit('SET_TEMPLATES', response.data.templates)
          commit('SET_FOLDERS', response.data.folders)
          commit('SET_TOP_FOLDERS', response.data)
          // Se agrega data para validar las carpetas seleccionadas previamente al seleccionar una plantilla
          if (response.data.templates.length > 0) {
            commit('SET_GO_BACK', goBackAux)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    getTemplate ({ commit }, payload) {
      return getTemplate(payload)
        .then(response => {
          return response
        })
    },

    getTemplateDetail ({ commit }, payload) {
      return getTemplateDetail(payload)
        .then(response => {
          return response
        })
    },

    getQuestionsTemplate ({ commit }, payload) {
      return getQuestionsTemplate(payload)
        .then(response => {
          response.data.map(question => {
            question.isValid = true
            question.isAdding = false
            question.newOption = ''

            if (question.typeQuestion !== 'list') {
              question.answerOptions = []
            }
          })
          return response
        })
    },

    convertToWordTemplate ({ commit }, payload) {
      return convertToWord(payload)
        .then(response => {
          return response
        })
    },

    saveContractTemplate ({ dispatch }, payload) {
      return saveTemplate(payload)
        .then(response => {
          dispatch('getTemplates')
          return response
        })
    },

    editContractTemplate ({ dispatch }, payload) {
      return editTemplate(payload)
        .then(response => {
          dispatch('getTemplates')
          return response
        })
    },

    editPublishedContractTemplate ({ dispatch }, payload) {
      return publishedToggle(payload)
        .then(response => {
          dispatch('getTemplates')
          return response
        })
    },

    getContentEditorByFile ({ commit }, payload) {
      return getContentByFile(payload)
        .then(response => {
          commit('SET_EDITOR_CONTENT', { content: response.data.content })
          return response
        })
        .catch(error => {
          return error.response
        })
    },

    getTemplateHistory ({ dispatch }, payload) {
      return getTemplateHistory(payload)
        .then(response => {
          return response
        })
    },

    getFilterTemplates ({ commit }, payload) {
      return getFilterTemplates(payload)
        .then(response => {
          commit('SET_TEMPLATES', response.data.templates)
          return response
        })
    },

    removeTemplate ({ commit }, templateId) {
      return removeTemplate(templateId)
    }
  },
  getters: {
    getTemplateById: (state) => (id) => {
      return state.templates.find(template => template.idtemplate === id)
    },

    getEditorQuestions (state) {
      const editorQuestions = state.currentTemplate.content.questionnaire.questions.map(
        (question) => {
          return { id: question.index, name: question.name }
        }
      )

      return editorQuestions
    }
  }
}
