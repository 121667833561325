import Vue from 'vue'
import Vuex from 'vuex'

/* Imports */
import state from './state'
import mutations from './mutations'
import getters from './getters'
import actions from './actions'

/* Modules */
import template from './modules/template'
import contract from './modules/contract'
import administration from './modules/administration'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    template,
    contract,
    administration
  }
})
