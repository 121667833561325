import Vue from 'vue'
import VueRouter from 'vue-router'

/* Routes */
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/* Guards */
router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)

  const loggedIn = localStorage.getItem('legalToken')

  if (authRequired && !loggedIn) {
    return next('/login')
  } else {
    next()
  }
})

export default router
