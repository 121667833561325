/* General Actions */
import { getAuthentication } from '@/api/login.api'
import { getCountries, getTemplateTypes, getAllFolders, getContractLeftFolders, getNavNotifications, getTemplateLeftFolders } from '@/api/configuration.api'
import { isLegalProfile } from '@/helpers/permissionValidate'

export default {
  getToken ({ commit }, payload) {
    return getAuthentication(payload)
      .then(response => {
        commit('SET_PROFILE', { profile: response.data.groups[0] })
        commit('SET_AREA', response.data.areas)
        commit('SET_USERNAME', response.data)
        commit('SET_COUNTRY', { country: response.data.country[0] })
        return response
      })
  },

  getCountries ({ commit }, payload) {
    return getCountries(payload)
      .then(response => {
        commit('SET_COUNTRIES', response.data)
        return response
      })
  },

  getTemplateTypes ({ commit }, payload) {
    return getTemplateTypes(payload)
      .then(response => {
        commit('SET_TEMPLATE_TYPES', response.data)
        return response
      })
  },

  getAllFolders ({ commit }, payload) {
    return getAllFolders(payload)
      .then(response => {
        commit('SET_ALL_FOLDERS', response.data)
        return response.data
      })
  },

  getContractLeftFolders ({ commit }, payload) {
    if (!isLegalProfile()) {
      if (!payload) payload = {}
      payload.country = localStorage.getItem('country')
    }
    return getContractLeftFolders(payload)
      .then(response => {
        commit('SET_CONTRACT_LEFT_FOLDERS', response.data)
        return response.data
      })
  },

  getTemplateLeftFolders ({ commit }, payload) {
    if (!isLegalProfile()) {
      if (!payload) payload = {}
      payload.country = localStorage.getItem('country')
    }
    return getTemplateLeftFolders(payload)
      .then(response => {
        commit('SET_TEMPLATE_LEFT_FOLDERS', response.data)
        return response.data
      })
  },

  getNavNotifications ({ commit }, payload) {
    return getNavNotifications(payload)
      .then(response => {
        commit('SET_NAV_NOTIFICATIONS', response.data)
        return response.data
      })
  }
}
