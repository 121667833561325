import { profiles } from './../utils/profiles'

const isLegalProfile = () => {
  const currentProfile = localStorage.getItem('profile')
  const legalProfile = profiles.find(element => element.name === 'Legal')
  const found = profiles.find(element => element.name === currentProfile)
  return legalProfile.permission === found.permission
}

export {
  isLegalProfile
}
