export default {
  SET_TOKEN (state, payload) {
    state.token = payload.token
  },

  SET_USERNAME (state, payload) {
    state.username = payload.username
  },

  SET_AREA (state, payload) {
    state.area = payload.area
  },

  SET_COUNTRY (state, payload) {
    state.country = payload.country
  },

  SET_PROFILE (state, payload) {
    state.profile = payload.profile
  },

  SET_SNACKBAR (state, snackbar) {
    state.snackbar = snackbar
  },

  SET_COUNTRIES (state, payload) {
    state.countries = payload
  },

  SET_TEMPLATE_TYPES (state, payload) {
    state.templateTypes = payload
  },

  SET_FOLDER_SELECTION (state, payload) {
    state.folderSelection = payload
  },

  SET_ALL_FOLDERS (state, payload) {
    state.allFolders = payload
  },

  SET_CONTRACT_LEFT_FOLDERS (state, payload) {
    state.contractLeftFolders = []
    setTimeout(() => {
      state.contractLeftFolders = payload
    }, 1000)
  },

  SET_TEMPLATE_LEFT_FOLDERS (state, payload) {
    state.templateLeftFolders = []
    setTimeout(() => {
      state.templateLeftFolders = payload
    }, 1000)
  },

  SET_NAV_NOTIFICATIONS (state, payload) {
    state.navNotifications = payload
  },

  SET_NAV_NOTIFICATIONS_NUMBER (state, payload) {
    state.navNotificationsNumber = payload
  }
}
