import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#435CC8',
        secondary: '#E75CE0',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        dark: '#1E1041',
        background: '#F3F7FD'
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  }
})
