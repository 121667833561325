import httpClient from './httpClient'

// Endpoints for countries

function getAdminCountries (payload) {
  const url = '/countries_admin/'
  return httpClient.get(url)
}

function addAdminCountries (payload) {
  const url = '/countries_admin/'
  return httpClient.post(url, payload)
}

function updateAdminCountries (payload) {
  const url = `/countries_admin/${payload.id}/`
  const body = {
    name: payload.name
  }
  return httpClient.patch(url, body)
}

// Endpoints for users

function getAdminUsers (payload) {
  const url = '/users/'
  return httpClient.get(url)
}

function getAdminUser (payload) {
  const url = `/users/${payload.id}/`
  return httpClient.get(url)
}

function updateAdminUsers (payload) {
  const url = `/users/${payload.id}/`
  delete payload.id
  delete payload.username
  return httpClient.patch(url, payload)
}

function addAdminUser (payload) {
  const url = '/users/'
  delete payload.active
  return httpClient.post(url, payload)
}

// Endpoints for areas

function getAdminAreas (payload) {
  const url = '/areas/'
  return httpClient.get(url)
}

function getAdminArea (payload) {
  const url = `/areas/${payload.id}/`
  return httpClient.get(url)
}

function updateAdminAreas (payload) {
  const url = `/areas/${payload.id}/`
  delete payload.id
  return httpClient.patch(url, payload)
}

// Endpoints for justifications

function getAdminJustifications (payload) {
  const url = '/justifications/?admin=true/'
  return httpClient.get(url)
}

function addAdminJustification (payload) {
  const url = '/justifications/'
  return httpClient.post(url, payload)
}

function updateAdminJustification (payload) {
  const url = `/justifications/${payload.id}/`
  delete payload.id
  return httpClient.patch(url, payload)
}

// Endpoints for notifications

function getAdminNotifications (payload) {
  const url = '/notifications/'
  return httpClient.get(url)
}

function updateAdminNotification (payload) {
  const url = `/notifications/${payload.id}/`
  delete payload.id
  return httpClient.patch(url, payload)
}

// Endpoints for template types

function getAdminTemplateTypes (payload) {
  const url = '/templates_types_admin/'
  return httpClient.get(url)
}

function addAdminTemplateType (payload) {
  const url = '/templates_types_admin/'
  return httpClient.post(url, payload)
}

function updateAdminTemplateType (payload) {
  const url = `/templates_types_admin/${payload.id}/`
  delete payload.id
  return httpClient.patch(url, payload)
}

export {
  getAdminCountries,
  addAdminCountries,
  updateAdminCountries,
  getAdminUsers,
  getAdminUser,
  updateAdminUsers,
  addAdminUser,
  getAdminAreas,
  getAdminArea,
  updateAdminAreas,
  getAdminJustifications,
  addAdminJustification,
  updateAdminJustification,
  getAdminNotifications,
  updateAdminNotification,
  getAdminTemplateTypes,
  addAdminTemplateType,
  updateAdminTemplateType
}
